import "./Report.scss";

import { useEffect, useState, useRef, Fragment } from "react";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "@nidi/html2canvas";
import axios from "axios";

import ReportHeader from "./../ReportHeader/ReportHeader";
import ReportFooter from "./../ReportFooter/ReportFooter";

import {
  ROOT_URI,
  API_ENDPOINT,
  FIRST_PAGE_IMAGE_PRIORITY,
  ARRYTHMIA_FOLDERS,
} from "../../constants/constants";
import FirstPage from "./FirstPage";
import LastPage from "./LastPage";
import UncontrolledInput from "../UncontrolledInput/UncontrolledInput";
import getImageDimensions from "../../helpers/getImageDimensions";
import Table from "../Table/Table";
import Loader from "../Loader/Loader";
import getImageMeta from "../../helpers/getImageMeta";
import { saveAs } from "file-saver";
import DairyTable from "../Table/DairyTable";
const Report = () => {
  const [report, setReport] = useState();
  const [userId, setUserId] = useState();
  const [pdfType, setPdfType] = useState("Full Report");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const reportRef = useRef();

  useEffect(() => {
    const userId = window.location.search.replace("?userid=", "");
    setUserId(userId);
  }, []);

  useEffect(() => {
    if (!userId) {
      return;
    }
    (async () => {
      try {
        let totalPages = 1;
        // Patient Details

        const imagesToShow = [];

        const [
          { data: patientDetails },
          { data: ecgDetails },
          { data: userSettings },
        ] = await Promise.all([
          axios.get(`${API_ENDPOINT}/patients/details?userid=${userId}`),
          axios.get(
            `${API_ENDPOINT}/common/getPatientTotalDetails?userid=${userId}`
          ),
          axios.get(`${API_ENDPOINT}/patients/settings?userid=${userId}`),
        ]);

        const { data: doctorDetails } = await axios.get(
          `${API_ENDPOINT}/androidapi/getDoctorInfo?userid=${patientDetails.doctorid}`
        );

        const noOfArrythmiaImagesToUse = Number(userSettings.graph_gen);

        const {
          data: { children: directoryTree },
        } = await axios.get(
          `${API_ENDPOINT}/common/patient_dir?userid=${userId}`
        );
        
        // get dairy data
        const { data: dairyData } = await axios.get(
          `${API_ENDPOINT}/patients/getDiary?userid=${userId}`
        );

        let i;
        for (i = 0; i < FIRST_PAGE_IMAGE_PRIORITY.length; i++) {
          const currentImage = FIRST_PAGE_IMAGE_PRIORITY[i];

          const directory = directoryTree.find(
            (val) => val.name === currentImage.key
          );

          if (directory) {
            imagesToShow.push(currentImage);
          }

          if (imagesToShow.length === 2) {
            break;
          }
        }

        // For TABLE/HR Graphs
        const tableImages = [];
        const hrImages = [];
        Object.keys(ecgDetails).forEach((name) => {
          if (name.includes("table")) {
            tableImages.push(name);
            ecgDetails[name] = ecgDetails[name].replace(/'/gi, '"');
          }
        });

        directoryTree.forEach(({ name, extension }) => {
          if (name.includes("hr") && extension === ".png") {
            hrImages.push(name);
          }
        });

        // For arrythmia graphs
        const chosenArrythmias = [];

        for (let j = 0; j < ARRYTHMIA_FOLDERS.length; j++) {
          const arrythmia = directoryTree.find(
            ({ name }) => name === ARRYTHMIA_FOLDERS[j].key
          );

          if (arrythmia) {
            chosenArrythmias.push({
              ...arrythmia,
              heading: ARRYTHMIA_FOLDERS[j].name,
            });
          }
        }

        const arrythmiaImages = [];

        let showHeading = false;
        chosenArrythmias.forEach((chosenArrythmia) => {
          showHeading = true;
          if (
            chosenArrythmia.name === "max" ||
            chosenArrythmia.name === "min"
          ) {
            return arrythmiaImages.push({
              heading: chosenArrythmia.heading,
              showHeading: showHeading,
              url: `${ROOT_URI}/assets/dist/user_records/${userId}/${chosenArrythmia.name}/cropped_image/max_${chosenArrythmia.name}.png`,
            });
          }
          const imageDirectories = chosenArrythmia.children[0].children.filter(
            ({ type }) => type === "directory"
          );

          imageDirectories.forEach((directory) => {
            directory.children.splice(noOfArrythmiaImagesToUse);

            directory.children.forEach(({ name }) => {
              arrythmiaImages.push({
                heading: chosenArrythmia.heading,
                showHeading: showHeading,
                url: `${ROOT_URI}/assets/dist/user_records/${userId}/${chosenArrythmia.name}/cropped_image/${directory.name}/${name}`,
              });
              showHeading = false;
            });
          });
        });

        await Promise.all(
          arrythmiaImages.map(async (image, index) => {
            const { height, width } = await getImageMeta(image.url);
            const scaledHeight = (height / width) * 500;

            arrythmiaImages[index] = {
              ...image,
              height:
                scaledHeight -
                0.23 * scaledHeight +
                (image.showHeading ? 17 : 0),
            };
            return true;
          })
        );

        // PAUSES
        const sinuses = ecgDetails.sinus_pause.split(",");

        // ATRIAL
        const pac = ecgDetails.pac.split(",");
        const afib = ecgDetails.afib.split(",");

        // VENTRIAL
        const pvc = ecgDetails.pvc.split(",");
        const vt = ecgDetails.vt.split(",");

        pac[0] = `${pac[0]}|${pac[1]}%`;
        pac[10] = `${pac[10]}%`;
        pac[11] = `${pac[11]}%`;

        afib[0] = `${afib[0]}|${afib[2]}%`;
        afib[2] = `${afib[2]}%`;

        pvc[0] = `${pvc[0]}|${pvc[1]}%`;
        pvc[10] = `${pvc[10]}%`;
        pvc[11] = `${pvc[11]}%`;

        // Separate date from pac 12 & 13
        let [pac12Value, pac12Date] = pac[12].split("@");
        let [pac13Value, pac13Date] = pac[13].split("@");
        pac12Date = pac12Date ? `@${pac12Date}` : "";
        pac13Date = pac13Date ? `@${pac13Date}` : "";
        pac[12] = pac12Value;
        pac[13] = pac13Value;

        const from = moment(new Date(ecgDetails.Data_from)).format(
          "YYYY/MM/DD@HH:mm"
        );

        const to = moment(new Date(ecgDetails.Data_to)).format(
          "YYYY/MM/DD@HH:mm"
        );

        const regex = /(<([^>]+)>)/gi;

        ecgDetails.findings = ecgDetails.findings
          .replace("<br>", "")
          .replace("</br>", "")
          .replace(regex, "")
          .split(". ")
          .map((val) => val.trim())
          .join(". \n");

        ecgDetails.duration = ecgDetails.duration.split(":");
        ecgDetails.duration.splice(3);
        ecgDetails.duration = ecgDetails.duration.join(" ");
        ecgDetails.duration = ecgDetails.duration.replace("d", " Day");

        setReport({
          ...ecgDetails,
          doctorName: doctorDetails.name,
          ...patientDetails,
          age: moment(new Date()).diff(
            moment(new Date(patientDetails.dob)).format("YYYY/MM/DD"),
            "years"
          ),
          pausesInExcess: sinuses[7],
          maxPause: sinuses[8],
          afib,
          pvc,
          pac,
          vt,
          images: imagesToShow,
          tableImages,
          hrImages,
          arrythmiaImages,
          totalPages,
          from,
          to,
          pac12Date,
          pac13Date,
          dairyData,
        });
      } catch (err) {
        console.log(err);
        setReport({});
      }
    })();
  }, [userId]);

  if (!report) {
    return "Loading...";
  }

  if (Object.keys(report).length === 0) {
    return "USER NOT FOUND";
  }

  const handleChange = (e) => {
    const newReport = { ...report };
    newReport[e.target.name] = e.target.value;
    setReport(newReport);
  };

  const handleDurationChange = (e) => {
    const newReport = { ...report };
    newReport.duration[e.target.name] = e.target.value;
    setReport(newReport);
  };

  const handleArrayChange = (key, index) => (e) => {
    const newReport = { ...report };
    report[key][index] = e.target.value;
    setReport(newReport);
  };

  const handleImageDelete = (index) => {
    const newSetOfArrythmiaImages = [...report.arrythmiaImages];

    const arrythmia = newSetOfArrythmiaImages[index];
    console.log(index)
    console.log(arrythmia.url)

    const response = axios.post('https://web.mybiocalculus.com:3002/api/common/removereportfile/', { userid: arrythmia.url });


    if (arrythmia.showHeading) {
      if (
        newSetOfArrythmiaImages[index + 1] &&
        !newSetOfArrythmiaImages[index + 1].showHeading
      ) {
        newSetOfArrythmiaImages[index + 1].showHeading = true;
        newSetOfArrythmiaImages[index + 1].height =
          newSetOfArrythmiaImages[index + 1].height + 17;
      }
    }
    newSetOfArrythmiaImages.splice(index, 1);
    setReport({ ...report, arrythmiaImages: newSetOfArrythmiaImages });
  };

  const generatePDF = (type) => {
    setPdfType(type);
  };

  const getPdf = async () => {
    window.scrollTo(0, 0);
    const reportContainer = document.querySelector(".report__content");

    const pages = reportContainer.childNodes;

    const totalPDFPages = pages.length;
    const scale = 2;
    const IMAGE_WIDTH = 595;
    const IMAGE_HEIGHT = 841;

    let images = Array(totalPDFPages).fill(null);

    images = await Promise.all(
      images.map(async (_, index) => {
        const node = pages[index];

        const canvas = await html2canvas(node, {
          allowTaint: true,
          useCORS: true,
          scale: index === 0 ? scale * 2 : scale,
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        });

        canvas.getContext("2d");
        const image = canvas.toDataURL("image/jpeg", 1.0);
        return image;
      })
    );

    const { w, h } = await getImageDimensions(images[0]);

    const pdf = new jsPDF("p", "px", [w, h], false);

    pdf.addImage(images[0], "jpeg", -8 * 2 * scale, 0, w, h);

    for (var i = 1; i < totalPDFPages; i++) {
      pdf.addPage();
      pdf.addImage(images[i], "jpeg", -8 * 2 * scale, 0, w, h);
    }

    return pdf;
  };

  const handlePdfDownload = async () => {
    setLoading(true);
    
    const pdf = await getPdf();

    await pdf.save(`Biocalculus_${report.name}_${report.device_id}.pdf`, {
      returnPromise: true,
    });

    setLoading(false);

      const output = await pdf.output("blob");

    const file = new File(
      [output],
      `Biocalculus_${report.name}_${report.device_id}_${pdfType
        .split(" ")
        .join("_")}.pdf`
    );

    const form = new FormData();

    form.append("file", file);
    form.append("type", pdfType);

    await axios.post(
      `${API_ENDPOINT}/common/patientpdfupload?userid=${userId}`,
      form
    );
    setUploading(false);

  };


    const handlestrippdf = async () => {
    setLoading(true);
    
    const pdf = await getPdf();



    

      const output = await pdf.output("blob");

    const file = new File(
      [output],
      `Biocalculus_${report.name}_${report.device_id}_${pdfType
        .split(" ")
        .join("_")}.pdf`
    );

    const form = new FormData();

    form.append("file", file);
    form.append("type", pdfType);

    await axios.post(
      `https://web.mybiocalculus.com/html/welcome/triggerecgstrip?userid=${userId}`,
      form
    );
    saveAs(`https://web.mybiocalculus.com/assets/dist/user_records/${userId}/${userId}.pdf`,
      `Full_ECG_${report.name}.pdf`);
    setLoading(false); 
    setUploading(false);

  };



  const handleSavePDFToServer = async () => {
    setUploading(true);

    const pdf = await getPdf();

    const output = await pdf.output("blob");

    const file = new File(
      [output],
      `Biocalculus_${report.name}_${report.device_id}_${pdfType
        .split(" ")
        .join("_")}.pdf`
    );

    const form = new FormData();

    form.append("file", file);
    form.append("type", pdfType);

    const article = { userid: "React POST Request Example" };
    await axios.post(`${API_ENDPOINT}/common/patientpdfupload`, article);
    setUploading(false);
  };

  const imageBlocks = report.tableImages.reduce(
    (acc, val, index) => {
      const totalSize = acc[acc.length - 1].reduce(
        (acc, val) => acc + val.size,
        0
      );

      const data = report[val];
      const size = (JSON.parse(data).length - 1) * 10.4 + 29.6 + 14;
      if (size + 80 + totalSize > 755) {
        acc.push([]);
      }

      acc[acc.length - 1].push({
        jsx: (
          <Fragment key={val}>
            <div className="report__images-wrapper">
              <Table data={data} />
            </div>
          </Fragment>
        ),
        size,
      });
      acc[acc.length - 1].push({
        jsx: (
          <div
            className="report__images-wrapper"
            key={val.replace("table", "hr")}>
            <img
              className="report__hr-image"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/${report.hrImages[index]}`}
              alt=""
            />
          </div>
        ),
        size: 80,
      });

      return acc;
    },
    [[]]
  );

  const totalSize = imageBlocks[imageBlocks.length - 1].reduce(
    (acc, val) => acc + val.size,
    0
  );

  if (totalSize + 183 > 755) {
    imageBlocks.push([]);
  }

  const lastImageBlockIndex = imageBlocks.length - 1;

  imageBlocks[lastImageBlockIndex].push({
    jsx: (
      <div className="report__images-wrapper" key="PLOT I">
        <div className="report__plots">
          <div className="report__plot">
            <p className="report__plot-title">ACTIVITY vs TIME</p>
            <img
              className="report__plot-graph"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/activity_time_plot.png`}
              alt=""
            />
          </div>
          <div className="report__plot">
            <p className="report__plot-title">ACTIVITY vs ARRHYTHMIA</p>
            <img
              className="report__plot-graph"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/activity_arr_plot.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    ),
    size: 183,
  });

  let lastBlockPageTotalLength = imageBlocks[lastImageBlockIndex].reduce(
    (acc, val) => acc + val.size,
    0
  );

  let addedNewPage = false;

  if (lastBlockPageTotalLength + 175 > 755) {
    addedNewPage = true;
    imageBlocks.push([]);
  }

  imageBlocks[imageBlocks.length - 1].push({
    jsx: (
      <div
        className="report__images-wrapper"
        key="PLOT II"
        style={{ marginTop: !addedNewPage && "-35px" }}>
        <div className="report__plots">
          <div className="report__plot">
            <p className="report__plot-title">Lorenz Plot - All Beats</p>
            <img
              className="report__plot-chart"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/lorentz_allbeats.png`}
              alt=""
            />
          </div>
          <div className="report__plot">
            <p className="report__plot-title">Lorenz Plot - Normal Beats</p>
            <img
              className="report__plot-chart"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/lorentz_normalbeats.png`}
              alt=""
            />
          </div>
        </div>
      </div>
    ),
    size: addedNewPage ? 210 : 175,
  });
  if(report.dairyData && report.dairyData.length) {
    const totalSize1 = imageBlocks[imageBlocks.length - 1].reduce(
      (acc, val) => acc + val.size,
      0
    );
    if (totalSize1 + 183 > 755) {
      imageBlocks.push([]);
    }
    imageBlocks[imageBlocks.length - 1].push({
      jsx: (
        <DairyTable key={'DAIRY_TABLE'} data={report.dairyData} />
      ),
      size: addedNewPage ? 210 : 175,
    });
  }

  let arrythmiaImagesPerPage;
  let totalPages;

  if (pdfType === "Full Report") {
    let arrythmiaImages = [...report.arrythmiaImages];

    let spaceRemainingInPreviousPage =
      755 -
      imageBlocks[imageBlocks.length - 1].reduce(
        (acc, val) => acc + val.size,
        0
      );

    let items = 0;
    let heightsOfNewImages = 0;
    for (const item in arrythmiaImages) {
      const image = arrythmiaImages[item];
      if (heightsOfNewImages + image.height > spaceRemainingInPreviousPage) {
        items = item;
        break;
      }
      heightsOfNewImages += image.height;
    }

    const splittedArrythmiaImages = arrythmiaImages.splice(0, items);

    splittedArrythmiaImages.forEach(({ url, heading, showHeading }, index) => {
      imageBlocks[imageBlocks.length - 1].push({
        jsx: (
          <Fragment key={url}>
            {showHeading && <UncontrolledInput initialValue={heading} />}
            <div className="report__images-wrapper">
              <img
                onDoubleClick={() => handleImageDelete(index)}
                key={url}
                className="report__arrythmia-images"
                src={url}
                alt={report.arrythmiaImages.label}
              />
            </div>
          </Fragment>
        ),
      });
    });

    arrythmiaImagesPerPage = arrythmiaImages.reduce(
      (acc, val, index) => {
        const remainingSpace = acc[acc.length - 1].reduce((acc, val) => {
          return acc + val.height;
        }, 0);

        if (remainingSpace + val.height > 755) {
          acc.push([]);
        }

        acc[acc.length - 1].push({
          ...val,
          index: index + splittedArrythmiaImages.length,
        });

        return acc;
      },
      [[]]
    );

    totalPages = 1 + imageBlocks.length + arrythmiaImagesPerPage.length + 1;
  } else {
    totalPages = 1 + imageBlocks.length;
  }

  return (
    <div className="report">
      <div className="report__buttons">
        <button
          className="report__button"
          onClick={

            pdfType === "Full Report"
              ? handlePdfDownload
              : () => generatePDF("Full Report")
          }>
          {pdfType === "Full Report" ? (
            loading ? (
              <>
                Building PDF ! <Loader />
              </>
            ) : (
              "Download Full Report"
            )
          ) : (
            "Generate Full Report"
          )}
        </button>
        <button
          className="report__button"
          onClick={
            pdfType === "Patient Report"
              ? handlePdfDownload
              : () => generatePDF("Patient Report")
          }>
          {pdfType === "Patient Report" ? (
            loading ? (
              <>
                Building PDF ! <Loader />
              </>
            ) : (
              "Download Patient Report"
            )
          ) : (
            "Generate Patient Report"
          )}
        </button>

        <button className="report__button"
                  onClick={
            pdfType === "Patient Reports"
              ? handlestrippdf
              : () => generatePDF("Patient Reports")
          }>
          {pdfType === "Patient Reports" ? (
            loading ? (
              <>
                Building PDF ! <Loader />
              </>
            ) : (
              "Generate ECG PDF"
            )
          ) : (
            "Generate ECG PDF"
          )}</button>
      </div>
      <div className="report__content" ref={reportRef}>
        <FirstPage
          {...{
            report,
            handleChange,
            handleDurationChange,
            handleArrayChange,
            totalPages,
            userId,
          }}
        />
        {imageBlocks.map((blocks, index) => (
          <div className="report__page" key={`IMAGE BLOCKS - ${index}`}>
            <ReportHeader
              patientId={report.device_id}
              age={report.age}
              name={report.name}
            />
            <div className="report__page-wrapper">
              {blocks.map(({ jsx }) => jsx)}
            </div>
            <ReportFooter page={2 + index} totalPage={totalPages} />
          </div>
        ))}

        {/* ARRYTHMIA PAGES */}
        {arrythmiaImagesPerPage &&
          arrythmiaImagesPerPage.map((images, index) => (
            <div className="report__page" key={`arrythmia ${index}`}>
              <ReportHeader
                patientId={report.device_id}
                age={report.age}
                name={report.name}
              />
              <div className="report__page-wrapper report__arrythmia-pages-wrapper">
                {images.map(({ url, heading, showHeading, index, height }) => (
                  <Fragment key={url}>
                    {showHeading && (
                      <UncontrolledInput initialValue={heading} />
                    )}
                    <div className="report__images-wrapper">
                      <img
                        onDoubleClick={() => handleImageDelete(index)}
                        className="report__arrythmia-images"
                        style={{ height: showHeading ? height - 17 : height }}
                        src={url}
                        alt={report.arrythmiaImages.label}
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
              <ReportFooter
                page={1 + imageBlocks.length + index + 1}
                totalPage={totalPages}
              />
            </div>
          ))}
        {pdfType === "Full Report" && (
          <LastPage report={report} totalPages={totalPages} />
        )}
      </div>
    </div>
  );
};

export default Report;
