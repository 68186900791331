import "./UncontrolledTextArea.scss";

const UncontrolledTextArea = ({ defaultValue, className }) => {
  return (
    <textarea
      className={className || "uncontrolled-text-area"}
      type="text"
      defaultValue={defaultValue}
    />
  );
};

export default UncontrolledTextArea;
