import "./ReportFooter.scss";

const ReportFooter = ({ page = 1, totalPage = 1 }) => {
  return (
    <div className="report-footer">
      <p>www.mybiocalculus.com</p>
      <p>&copy;WAFERCHIPS</p>
      <p>
        Page {page} of {totalPage}
      </p>
    </div>
  );
};

export default ReportFooter;
