import "./ReportHeader.scss";

const ReportHeader = ({ name, age, patientId }) => {
  return (
    <div className="report-header">
      <p>{patientId}</p>
      <p>{name}</p>
      <p>Age : {age}</p>
    </div>
  );
};

export default ReportHeader;
