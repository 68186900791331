import { useEffect, useState } from "react";
import UncontrolledTextArea from "../UncontrolledTextArea/UncontrolledTextArea";
import "./Table.scss";

const hoursToShade = [22, 23, 24, 0, 1, 2, 3, 4, 5, 6];

const Table = ({ data: rawData }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let allItems = JSON.parse(rawData.replace(/'/gi, '"'));

    const heading = [...allItems][0][0];
    allItems.splice(0, 1);

    setData({ data: allItems, heading });
  }, [rawData]);

  if (!data) {
    return "";
  }

  return (
    <div className="table">
      <p className="table__header">{data.heading}</p>
      <table className="table__table">
        <thead>
          <tr>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Hour" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="Beats" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Max HR" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Min HR" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Avg HR" />
            </th>
            <th style={{ width: "22px" }}>
              <UncontrolledTextArea defaultValue="Tachy (SR > 100)" />
            </th>
            <th style={{ width: "22px" }}>
              <UncontrolledTextArea defaultValue="Brady (SR < 60)" />
            </th>
            <th style={{ width: "15px" }}>
              <UncontrolledTextArea defaultValue="SP > 2 sec" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="V ectopy" />
            </th>
            <th style={{ width: "16px" }}>
              <UncontrolledTextArea defaultValue="V Pair" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="V Triplet" />
            </th>
            <th style={{ width: "30px" }}>
              <UncontrolledTextArea defaultValue="V Bigemni" />
            </th>
            <th style={{ width: "34px" }}>
              <UncontrolledTextArea defaultValue="V Trigemny" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="A Ectopy" />
            </th>
            <th style={{ width: "18px" }}>
              <UncontrolledTextArea defaultValue="A Pair" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="A Triplet" />
            </th>
            <th style={{ width: "33px" }}>
              <UncontrolledTextArea defaultValue="A Bigemny" />
            </th>
            <th style={{ width: "34px" }}>
              <UncontrolledTextArea defaultValue="A Trigemny" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="VT" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="SVT" />
            </th>
            <th>
              <UncontrolledTextArea defaultValue="AFib" />
            </th>
            <th style={{ width: "30px" }}>
              <UncontrolledTextArea defaultValue="Atrial Run" />
            </th>
            <th style={{ width: "38px" }}>
              <UncontrolledTextArea defaultValue="Ventricular Run" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((row, index) => (
            <tr
              key={data.heading + index}
              className={
                hoursToShade.includes(row[0]) ? "table__row-shade" : ""
              }>
              {row.map((dt, i) => (
                <td key={data.heading + index + i}>
                  <input defaultValue={dt} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
