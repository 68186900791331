import moment from "moment";
import UncontrolledTextArea from "../UncontrolledTextArea/UncontrolledTextArea";
import "./Table.scss";

const DairyTable = ({ data }) => {
  return (
    <div className="report__images-wrapper " style={{ marginBottom: "10px" }}>
        <div className="table">
          <p className="header">{"Diary Notes"}</p>
          <table className="table__table fontBookman" width={"100%"}>
            <thead >
              <tr>
                <th width="40px" >
                  <UncontrolledTextArea className={'uncontrolled-text-area dairy-text-area'} defaultValue="Sl no" />
                </th>
                <th>
                  <UncontrolledTextArea className={'uncontrolled-text-area dairy-text-area'} defaultValue="Date" />
                </th>
                <th>
                  <UncontrolledTextArea className={'uncontrolled-text-area dairy-text-area'} defaultValue="Symptoms" />
                </th>
                <th>
                  <UncontrolledTextArea className={'uncontrolled-text-area dairy-text-area'} defaultValue="Activity" />
                </th>
                <th>
                  <UncontrolledTextArea className={'uncontrolled-text-area dairy-text-area'} defaultValue="Duration" />
                </th>
                <th>
                  <UncontrolledTextArea className={'uncontrolled-text-area dairy-text-area'} defaultValue="Remark" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length &&
                data.map((row, index) => (
                  <tr key={row._id} className={""}>
                    <td className="textCenter">{index + 1} </td>
                    <td>
                      <input className="fontBookman"
                        defaultValue={moment(new Date(row.datetime)).format(
                          "YYYY/MM/DD HH:mm"
                        )}
                      />
                    </td>
                    <td>
                      <input className="fontBookman" defaultValue={row.diary_notes} />{" "}
                    </td>
                    <td>
                      <input className="fontBookman" defaultValue={row.event_activity} />{" "}
                    </td>
                    <td>
                      <input className="fontBookman" className="textCenter" defaultValue={row.act_duration} />{" "}
                    </td>
                    <td>
                    <input className="fontBookman" defaultValue={row.remark} />{" "}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default DairyTable;
