import ReportFooter from "../ReportFooter/ReportFooter";
import Input from "../Input/Input";
import Label from "../Label/Label";
import Logo from "./../../assets/images/Logo.png";
import { ROOT_URI } from "../../constants/constants";
import DynamicTextArea from "../DynamicTextArea/DynamicTextArea";

const FirstPage = ({
  report,
  handleChange,
  handleArrayChange,
  totalPages,
  userId,
}) => {
  return (
    <div className="report__page">
      {/* <img src={Template} alt="" className="report__template" /> */}
      <div className="report__header">
        <img src={Logo} alt="Logo" className="report__header-logo" />
        <div className="report__title">
          <p>Ambulatory Cardiac Monitor</p>
          <p>Study Report</p>
        </div>
        <p className="report__inverse-header report__enrollment-duration">
          Enrollment Duration
        </p>
        <p className="report__underline-header report__analysis-time">
          Analysis Time
        </p>
      </div>
      <div
        className="report__form-container"
        style={{ left: "20px", top: "82.34px" }}>
        {/* FIRST ROW */}
        <div className="report__form-row">
          <Input
            label="Patient Name"
            name="name"
            labelWidth={73}
            inputWidth={110}
            value={report.name}
          />

          <Input
            label="Age"
            labelWidth={35}
            inputWidth={70}
            value={report.age}
            name="age"
          />

          <Input
            value={report.exp_device_use_days}
            name="day"
            inputWidth={20}
            placeholder="30"
          />

          <Label label="Day" name="day" labelWidth={50} showSeparator={false} />

          <Label label="Duration" variant="small" labelWidth={33} />
          <Input
            value={report.duration}
            name="duration"
            placeholder="30"
            inputWidth={120}
          />
        </div>

        {/* SECOND ROW */}
        <div className="report__form-row">
          <Input
            label="Patient ID"
            labelWidth={73}
            inputWidth={70}
            value={report.device_id}
            name="device_id"
          />
          <Input
            label="Gender"
            labelWidth={50}
            inputWidth={40}
            value={report.gender}
            name="gender"
          />
          <Input
            label="From"
            labelWidth={27}
            inputWidth={125}
            value={report.from}
            placeholder="From"
            name="from"
          />
          <Input
            variant="small"
            label="Beats"
            labelWidth={20}
            inputWidth={100}
            value={report.total_beatscount}
            name="beats"
          />
        </div>
        {/* THIRD ROW */}
        <div className="report__form-row">
          <Input
            label="Indication"
            labelWidth={50}
            inputWidth={90}
            value={report.indication}
            name="indication"
          />

          <Input
            label="BMI"
            labelWidth={30}
            inputWidth={50}
            value={parseInt(report.weight/((report.height/100)*(report.height/100)))}
            name="bmi"
          />

          <Input
            label="To"
            labelWidth={31}
            inputWidth={110}
            value={report.to}
            name="to"
            placeholder="To"
          />
                    <Input
            label="CHA2DS2-VASc Score"
            labelWidth={120}
            inputWidth={100}
            value={report.CHA2Vasc_Score}
            name="CHA2Vasc"
            placeholder="0"
          />
        </div>
        {/* FOURTH ROW */}
        <div className="report__form-row">
          <Input
            label="Height"
            labelWidth={46}
            inputWidth={25}
            value={report.height}
            name="height"
          />
          <Label label="Cm" showSeparator={false} labelWidth={20} />

          <Input
            label="Weight"
            labelWidth={46}
            inputWidth={20}
            value={report.weight}
            name="weight"
          />
          <Label label="Kg" showSeparator={false} labelWidth={20} />

          <Input
            label="Referring Physician"
            labelWidth={110}
            inputWidth={220}
            value={report.doctorName}
            name="doctorName"
          />
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--1">
        <div className="report__mid-form-header">
          <p>ATRIAL ECTOPICS</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="AE Total"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={140}
            value={report.pac[0]}
          />
          <Input
            label="AE Pair"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.pac[5]}
          />
          <Input
            label="AE Long RUN"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pac[12]}
          />
          <Input
            showSeparator={false}
            label=" "
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={135}
            value={report.pac12Date}
            name="pac12Date"
          />
          <Input
            label="Max HR AE Run"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pac[13]}
          />
          <Input
            showSeparator={false}
            label=" "
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={135}
            value={report.pac13Date}
            name="pac13Date"
          />
          <Input
            label="AE Trigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pac[10]}
          />
          <Input
            label="AE Bigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pac[11]}
          />
          <Input
            label="Afib / Flutter"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.afib[0]}
          />
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--2">
        <div className="report__mid-form-header">
          <p>VENTRICULAR ECTOPICS</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="VE Total"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pvc[0]}
          />
          <Input
            label="VE Pair"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pvc[5]}
          />
          <Input
            label="VE Long RUN"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={126}
            value={report.pvc[12]}
          />

          <Input
            label="Max HR VE Run"
            labelFontFamily="Bookman Old Style"
            labelWidth={103}
            inputWidth={126}
            value={report.pvc[13]}
          />

          <Input
            label="VE Trigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={140}
            value={report.pvc[10]}
          />

          <Input
            label="VE Bigeminy"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={140}
            value={report.pvc[11]}
          />
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--3">
        <div className="report__mid-form-header">
          <p>PAUSES</p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Pauses in excess of 2.5 Sec"
            labelFontFamily="Bookman Old Style"
            labelWidth={170}
            inputWidth={40}
            value={report.pausesInExcess}
            name="pausesInExcess"
          />
          <Input
            label="Max Pause"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.maxPause}
            name="maxPause"
          />
        </div>
      </div>

      <div className="report__mid-form-container report__mid-form-container--4">
        <div className="report__mid-form-header">
          <p>
            HEART RATE
            <span
              style={{
                fontSize: "9.5px",
                fontFamily: "Bookman Old Style",
                paddingLeft: "2px",
              }}>
              [4 RR Interval]
            </span>
          </p>
        </div>

        <div className="report__mid-form">
          <Input
            label="Maximum HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.max_hr}
            name="max_hr"
          />
          <Input
            label="Minimum HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.min_hr}
            name="min_hr"
          />
          <Input
            label="Average HR"
            labelFontFamily="Bookman Old Style"
            labelWidth={105}
            inputWidth={100}
            value={report.avg_heartrate}
            name="avg_heartrate"
          />
        </div>
      </div>

      <div className="report__images">
        {report.images.map((image) => (
          <div className="report__imageContainer" key={image.key}>
            <input className="report__image-title" defaultValue={image.name} />
            <img
              className="report__image"
              src={`${ROOT_URI}/assets/dist/user_records/${userId}/${image.key}/cropped_image/max_${image.key}.png`}
              alt=""
            />
          </div>
        ))}
      </div>

      <img
        className="report__pie-chart"
        src={`${ROOT_URI}/assets/dist/user_records/${userId}/main_pie_chart.png`}
        alt="PIE CHART"
      />

      <div className="report__findingsninterpretation">
        <div className="report__findings">
          <p className="header">FINDINGS</p>
          <div style={{ flex: 1 }}>
            <DynamicTextArea value={report.findings} />
          </div>
        </div>
        <div className="report__interpretation">
          <p className="header">INTERPRETATION</p>
          <textarea
            type="paragraph"
            defaultValue={report.Interpretation}
            name="findings"
            className="content"></textarea>
        </div>
      </div>

      <ReportFooter page="1" totalPage={totalPages} />
    </div>
  );
};

export default FirstPage;
