import { useState } from "react";
import ReportFooter from "../ReportFooter/ReportFooter";
import ReportHeader from "../ReportHeader/ReportHeader";
import lastPageTexts from "./lastPageTexts.json";

const LastPage = ({ report, totalPages }) => {
  const [texts, setTexts] = useState(lastPageTexts);

  const add = (index) => {
    const newSetOfTexts = [
      ...[...texts].splice(0, index + 1),
      { text: "" },
      ...[...texts].splice(index + 1),
    ];
    setTexts(newSetOfTexts);
  };

  const remove = (index) => {
    const newSetOfTexts = [...texts];
    newSetOfTexts.splice(index, 1);
    setTexts(newSetOfTexts);
  };

  const handleChange = (val, index) => {
    const newSetOfTexts = [...texts];
    newSetOfTexts[index].text = val;
    setTexts(newSetOfTexts);
  };

  return (
    <div className="report__page">
      <ReportHeader
        patientId={report.device_id}
        age={report.age}
        name={report.name}
      />

      <div className="report__page-wrapper report__finalpage-wrapper">
        <p className="report__page-header report__finalpage-header">
          DISCLAIMER:
        </p>

        <textarea
          style={{ height: "115px" }}
          className="report__finalpage-description"
          defaultValue={`Software analysis is based on the quality of ECG data recorded by biocalculus. Frequent loose connection of device from the electrodes, vigorous movement by the patient and exerting pressure on the device can result in data loss/artifacts/noisy signals which can sometimes be misinterpreted as ectopic complexes or arrhythmias and can affect the analysis. CHA2DS2-VASc Score for Stroke Risk Assessment is calculated based on the patient provided information received via questionnaire form in patient signup\nArrhythmias are classified based on the following criteria:`}
        />

        <ul className="report__criterias">
          {texts.map(({ text, height }, index) => (
            <Li
              key={"TEXTS" + index}
              text={text}
              height={height}
              index={index}
              remove={remove}
              add={add}
              handleChange={handleChange}
            />
          ))}
        </ul>

        <ReportFooter page={totalPages} totalPage={totalPages} />
      </div>
    </div>
  );
};

const Li = ({ text, handleChange, height, remove, add, index }) => {
  return (
    <li className="report__criteria">
      <textarea
        value={text}
        onChange={(e) => handleChange(e.target.value, index)}
        style={height ? { height } : {}}
      />
      <div>
        <button onClick={() => remove(index)}>Remove</button>
        <button onClick={() => add(index)}>Add Row Below</button>
      </div>
    </li>
  );
};

export default LastPage;
