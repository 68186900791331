import { useState, useEffect } from "react";

const UncontrolledInput = ({ initialValue }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      className="report__page-header report__arrythmia-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      id={initialValue}
      name={initialValue}
    />
  );
};

export default UncontrolledInput;
